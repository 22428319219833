<template>
	<div class="content--loading" v-if="loading">
			<filling-circle color="#057DCD" :size="42" />
	</div>
	
	<div class="content__list" v-else> 
		<transaction-table :header="header" :list="transactions" />
	</div>
	
</template>

<script>
import TransactionTable from '@/components/Table.vue'
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
export default {
	data(){
		return {
			loading: true,
			header:{ name:'',quantity:{substitute:'No.#'},amount:{substitute:'Evaluation'}, },
		}
	},
	components: { TransactionTable,FillingCircle },
	name: 'ProductList',
	computed:{
		id(){
			return this.$route.params.id
		},

		getBranchId(){
			return this.$store.state.branchId
		},
		transactions(){
			let transactionsObject = this.$store.state.analysis.transactions
			let newTransactionList = []

			// let list = [
			// 	{name:'Net Transactions', ...transactionsObject['net_transactions']}
			// ]
			
			for(let transaction in transactionsObject){
				newTransactionList.push({
					name: transaction.toUpperCase().replaceAll('_',' '),
					quantity: transactionsObject[transaction].quantity,
					amount: transactionsObject[transaction].amount,
				})
			}

			return newTransactionList
		},

		range(){
			return this.$store.state.analysis.transactionsDateTimeRange
		}	
	},

	methods:{
		setBranchId(){
			this.$store.commit({type:'setBranchId',branchId:this.id})
		},
	},
	watch: {
        // whenever question changes, this function will run
        
        range: function () {
			this.loading = true
            this.$store.dispatch({
                type:'analysis/loadTransactions',
				id:this.id
            }).then((response)=>{
				this.loading=false
				console.log(response)
				console.log(this.transactions)
			}).
			catch((error)=>{
				this.loading=false
				console.error(error)
			})
        }
    },


	mounted(){
		this.$store.dispatch({type:'analysis/loadTransactions',id:this.id}).then((response)=>{
			this.loading=false
			if(!this.getBranchId && this.id) this.setBranchId()
			console.log(response)
			console.log(this.transactions)
		}).
		catch((error)=>{
			this.loading=false
			console.error(error)
		})
	}

}
</script>
